import { useTheme } from "@emotion/react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import AppCarouselItem from "./item";

type Props = {
  slides: React.ReactNode[];
};

const AppCarousel = ({ slides }: Props) => {
  const theme = useTheme();

  return (
    <Slider
      dots
      infinite={false}
      initialSlide={0}
      slidesToShow={3}
      slidesToScroll={3}
      autoplay
      responsive={[
        {
          breakpoint: theme.breakpoints.tablet,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: theme.breakpoints.desktop,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ]}
    >
      {slides.map((slide, index) => (
        <AppCarouselItem last={index === slides.length - 1} key={index}>
          {slide}
        </AppCarouselItem>
      ))}
    </Slider>
  );
};

export default AppCarousel;
