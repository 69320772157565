import { useTheme } from "@emotion/react";
import React from "react";
import { Flex } from "reflexbox";
import AppMarkdown from "../markdown";

const AppNewsCarouselItem = ({
  title,
  teaser,
}: GatsbyTypes.NewsItemFragment) => {
  const theme = useTheme();

  return (
    <Flex flexDirection="column">
      <h4 style={{ marginBottom: theme.spacing(0), fontWeight: 600 }}>
        {title}
      </h4>
      <AppMarkdown css={{ fontWeight: 300 }} content={teaser} />
      <a style={{}} href={`info-og-nyheder/${encodeURIComponent(title ?? "")}`}>
        Læs mere
      </a>
    </Flex>
  );
};

export default AppNewsCarouselItem;
