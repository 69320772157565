import { useTheme } from "@emotion/react";
import React from "react";
import AppCarousel from "../carousel/carousel";
import AppNewsCarouselItem from "./news-carousel-item";

type Props = {
  items: GatsbyTypes.NewsItemFragment[];
};

const AppNewsCarousel = ({ items }: Props) => {
  const theme = useTheme();

  return (
    <div
      css={{
        width: "80%",
        minHeight: 300,
        margin: `${theme.spacing(4)}px auto`,
      }}
    >
      <AppCarousel
        slides={items
          .sort(
            (a, b) =>
              (!!b.date ? new Date(b.date).valueOf() : 0) -
              (!!a.date ? new Date(a.date).valueOf() : 0)
          )
          .map((item) => (
            <AppNewsCarouselItem {...item} key={item.title} />
          ))}
      ></AppCarousel>
    </div>
  );
};

export default AppNewsCarousel;
