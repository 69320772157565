import styled from "@emotion/styled";
import React from "react";
import StrokedBox from "../stroked-box";
import Ripples from "react-ripples";

interface Props {
  specialities: GatsbyTypes.LandingQuery["specialities"]["edges"];
}

const Root = styled(StrokedBox)(({ theme }) => ({
  width: "60vw",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-around",
  marginTop: theme.spacing(6),
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: 0,
  padding: theme.spacing(2),
  [theme.mq.tablet]: {
    width: "50vw",
    maxWidth: 700,
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(4),
  },
}));

const SpecialityRipple = styled(Ripples)(({ theme }) => ({
  marginTop: theme.spacing(1) / 2,
  marginBottom: theme.spacing(1) / 2,
  height: 50,
  width: "90%",
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.secondary.contrastText,
  borderBottomStyle: "solid",
  [theme.mq.tablet]: {
    width: "45%",
  },
}));

const Speciality = styled.a(({ theme }) => ({
  height: "100%",
  width: "100%",
  fontWeight: 400,
  background: "transparent",
  outline: "none",
  border: "none",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  color: theme.colors.secondary.contrastText,
  textDecoration: "none",
  fontSize: "5vw",
  [theme.mq.tablet]: {
    fontSize: 20,
  },
}));

const Img = styled.img(({ theme }) => ({
  width: "6vw",
  paddingLeft: theme.spacing(2),
  [theme.mq.tablet]: {
    width: 30,
    paddingLeft: theme.spacing(2),
  },
}));

const Text = styled.span(({ theme }) => ({
  textAlign: "left",
  paddingLeft: theme.spacing(1) / 2,
  flex: 1,
  [theme.mq.tablet]: {
    paddingLeft: theme.spacing(2),
  },
}));

const AppLandingSpecialities = ({ specialities }: Props) => {
  return (
    <Root perspective="right" gradient>
      {specialities.map(({ node: { frontmatter } }) => {
        return (
          <SpecialityRipple key={frontmatter?.title}>
            <Speciality
              href={`/forretningsområder/${frontmatter?.slug?.toLowerCase()}`}
            >
              <Img src={frontmatter?.icon} />
              <Text> {frontmatter?.title} </Text>
            </Speciality>
          </SpecialityRipple>
        );
      })}
    </Root>
  );
};

export default AppLandingSpecialities;
