import React from "react";
import styled from "@emotion/styled";
import AppButton from "../components/button";
import { css } from "@emotion/react";
import { graphql, Link, PageProps } from "gatsby";
import AppLandingSpecialities from "../components/landing/landing-specialities";
import AppNewsCarousel from "../components/landing/news-carousel";
import AppContainer from "../components/container";
import AppContactBox from "../components/contact/contact-box";
import { Helmet } from "react-helmet";
import AppMarkdown from "../components/markdown";
import { Parallax } from "react-scroll-parallax";

const boxHeightVh = 50;

const TopContainer = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  [props.theme.mq.desktop]: {
    flexDirection: "row",
  },
}));

const Left = styled.div({
  display: "flex",
  flex: "1 1 50%",
  flexDirection: "column",
  justifyContent: "space-between",
  alignSelf: "stretch",
});

const TitleBox = styled.div(({ theme }) => ({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "auto",
  paddingTop: 50,
  paddingBottom: 50,
  backgroundColor: theme.colors.secondary.light,
  [theme.mq.desktop]: {
    height: `${boxHeightVh}vh`,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.pagePad,
    alignItems: "flex-start",
  },
}));

const Title = styled.h1(({ theme }) => ({
  marginTop: 0,
  marginBottom: theme.spacing(1),
  fontWeight: "bold",
  fontSize: "5.9vw",
  textAlign: "center",
  "& span": {
    display: "block",
  },
  [theme.mq.desktop]: {
    textAlign: "left",
    width: `calc(100% - ${theme.pagePad * 2}px)`,
  },
}));

const TitleTop = styled.span(({ theme }) => ({
  fontWeight: 300,
  fontSize: "6vw",
  color: theme.colors.secondary.main,
  [theme.mq.desktop]: {
    fontSize: 40,
  },
}));

const TitleMiddle = styled.span(({ theme }) => ({
  fontSize: "7vw",
  [theme.mq.desktop]: {
    fontSize: 50,
  },
  [theme.mq.ultraWide]: {
    fontSize: 60,
  },
}));

const TitleBottom = styled.span(({ theme }) => ({
  lineHeight: "13vw",
  fontSize: "14vw",
  marginBottom: theme.spacing(1),
  [theme.mq.desktop]: {
    lineHeight: 1,
    fontSize: 60,
    marginBottom: 0,
  },
  [theme.mq.desktop]: {
    lineHeight: 1,
    fontSize: 80,
  },
}));

const TeaserText = styled.div(({ theme }) => ({
  marginLeft: theme.pagePadMobile,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  width: `calc(100% - ${theme.pagePadMobile * 2}px)`,
  [theme.mq.desktop]: {
    marginLeft: theme.pagePad,
    width: `calc(100% - ${theme.pagePad * 2}px)`,
  },
}));

const VideoBox = styled.div((props) => ({
  zIndex: -1,
  height: `${boxHeightVh}vh`,
  width: "100%",
  marginTop: 0,
  backgroundColor: props.theme.colors.primary.light,
  position: "relative",
  overflow: "hidden",
  [props.theme.mq.desktop]: {
    width: "50%",
    marginTop: `${boxHeightVh / 2}vh`,
  },
}));

const Image = styled.img({
  height: "100%",
  width: "auto",
});

const Video = styled.video({
  minHeight: "100%",
  minWidth: "100%",
  height: "auto",
  width: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

const IndexPage = ({ data }: PageProps<GatsbyTypes.LandingQuery>) => {
  return (
    <AppContainer flexDirection="column">
      <Helmet title="RWS | Advokatkontor"></Helmet>
      <TopContainer>
        <Left>
          <TitleBox>
            <Title>
              <TitleTop>{data.page?.frontmatter?.titleLineOne}</TitleTop>
              <TitleMiddle>{data.page?.frontmatter?.titleLineTwo}</TitleMiddle>
              <TitleBottom>
                {data.page?.frontmatter?.titleLineThree}
              </TitleBottom>
            </Title>
            <AppButton component={Link} to="/kontakt">
              {data.page?.frontmatter?.actionButtonText}
            </AppButton>
          </TitleBox>
          <div
            css={css`
              flex: 1;
            `}
          />
          <TeaserText>
            <AppMarkdown content={data.page?.frontmatter?.bottomText} />
          </TeaserText>
        </Left>
        <VideoBox>
          <Image src={data.page?.frontmatter?.imageUrl} />
          {/* <Video autoPlay loop muted>
            <source src={data.page?.frontmatter?.videoUrl} />
          </Video> */}
        </VideoBox>
      </TopContainer>
      <AppLandingSpecialities specialities={data.specialities.edges} />
      <AppNewsCarousel
        items={data.news.edges.flatMap((edge) =>
          !!edge.node.frontmatter ? [edge.node.frontmatter] : []
        )}
      />
      <AppContactBox />
    </AppContainer>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query Landing {
    page: markdownRemark(fileAbsolutePath: { regex: "/landing/" }) {
      frontmatter {
        title
        titleLineOne
        titleLineTwo
        titleLineThree
        actionButtonText
        actionButtonLink
        imageUrl
        bottomText
      }
    }
    specialities: allMarkdownRemark(
      filter: { fields: { collection: { eq: "specialities" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            icon
            slug
          }
        }
      }
    }
    news: allMarkdownRemark(
      filter: { fields: { collection: { eq: "news" } } }
    ) {
      edges {
        node {
          frontmatter {
            ...NewsItem
          }
        }
      }
    }
  }
`;
