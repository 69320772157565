import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import rgba from "polished/lib/color/rgba";
import React from "react";

const Root = styled.div(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  borderRightStyle: "solid",
  borderRightColor: rgba(theme.colors.primary.main, 0.7),
}));

const AppCarouselItem: React.FC<{ last: boolean }> = ({ children, last }) => {
  const theme = useTheme();

  return <Root style={{ borderRightWidth: last ? 0 : 1 }}>{children}</Root>;
};

export default AppCarouselItem;
